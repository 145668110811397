







import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
@Observer
@Component({
  components: {},
})
export default class CountDown extends Vue {
  @Prop({ default: 0 }) value
}
